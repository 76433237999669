import { graphql } from "gatsby";
import { isBrowser } from "../utils/browserUtils";
import {IndexPage as FOSLP} from "./index"

if (isBrowser) {
  localStorage.setItem('access', 'true')
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["headerSection", "index", "common", "helpSection", "claimsSection", "mlp-testimonials", "navbar", "footer", "getQuoteModal", "threat-events", "extraBenefits", "stepsToProtectBusiness"]
        }, 
        language: {eq: $language 
      }
    }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default FOSLP